<template>
  <div class="bg-white pb-5">
    <div class="d-none d-md-flex">
      <HeroCurved/>
    </div>
    <div class="main-container p-0">
      <div class="container pb-4 pt-3 position-relative bg-white content d-flex flex-column align-items-center">
        <img :src="require('@/assets/Logos/etn-cwp-dark.svg')" class="my-md-4 my-3" width="175px">
        <transition name="component-fade" mode="out-in">
          <b-spinner class="my-5" label="Spinner" v-if="!this.pageLoaded"/>
          <div v-else>
            <!-- CONNECT -->
            <div v-if="this.linkedAccount === 204 && this.$route.params.hasApp" class="connect">
              <h2 class="font-weight-bold mx-auto">{{ $t('connect_app.connect.title') }}</h2>
              <!--desktop-->
              <div class="d-none d-md-flex flex-column">
                <ol class="mt-2 mb-0 p-0">
                  <li class="mb-1">{{ $t('connect_app.connect.list_item_1') }}</li>
                  <li class="mb-1">{{ $t('connect_app.connect.list_item_2') }}</li>
                  <li class="mb-1">{{ $t('connect_app.connect.list_item_3') }}</li>
                </ol>
                <div v-if="!accountInUse" class="my-2">
                  <div v-if="!qrLoaded" class="qr-loader mx-auto d-flex flex-column justify-content-center align-items-center mt-3">
                    <b-spinner class="mb-3" label="Spinner"/>
                    <span>{{ $t('connect_app.refreshing_qr') }}</span>
                  </div>
                  <div v-else>
                    <vue-qrcode :width=200 :value="this.etnDeeplink + this.qrData" />
                  </div>
                </div>
              </div>
              <!--mobile-->
              <div class="d-flex flex-column d-md-none">
                <p class="mb-4 mt-2">{{ $t('connect_app.connect.mobile_copy') }}</p>
                <a class="btn btn-electroneum px-4 py-2 d-flex justify-content-center w-100" :href="this.etnDeeplink + this.qrData">
                  <span class="mr-3">{{ $t('connect_app.cta.connect_with') }}</span>
                  <img v-lazy="require('@/assets/Logos/etn-cwp-white.svg')" width="100" />
                </a>
              </div>
            </div>

            <!-- RECEIVE -->
            <div v-if="this.linkedAccount === 204 && !this.$route.params.hasApp" class="receive">
              <!--desktop-->
              <div class="d-flex flex-column">
                <h2 class="font-weight-bold mx-auto">{{ $t('connect_app.receive.title') }}</h2>
                <ol class="mt-2 mb-2 p-0">
                  <li class="mb-1">{{ $t('connect_app.receive.list_item_1') }}</li>
                  <li class="mb-1">{{ $t('connect_app.receive.list_item_2') }}</li>
                  <li class="mb-1 d-none d-md-block">{{ $t('connect_app.receive.list_item_3') }}</li>
                  <li class="mb-1 d-block d-md-none">{{ $t('connect_app.receive.list_item_3_mobile') }}</li>
                </ol>
                <div v-if="!accountInUse" class="my-2 d-none d-md-flex mx-auto">
                  <div v-if="!qrLoaded" class="qr-loader mx-auto d-flex flex-column justify-content-center align-items-center mt-3">
                    <b-spinner class="mb-3" label="Spinner"/>
                    <span>{{ $t('connect_app.refreshing_qr') }}</span>
                  </div>
                  <div v-else>
                    <vue-qrcode :width=200 :value="this.etnDeeplink + this.qrData" />
                  </div>
                </div>
              </div>
              <!--mobile-->
              <div class="d-flex flex-column d-md-none">
                <a class="btn btn-electroneum px-4 py-2 d-flex justify-content-center w-100" :href="this.etnDeeplink + this.qrData">
                  <span class="mr-3">{{ $t('download') }}</span>
                  <img v-lazy="require('@/assets/Logos/etn-cwp-white.svg')" width="100" />
                </a>
              </div>
            </div>

            <!-- SUCCESS -->
            <transition name="component-fade" mode="out-in">
              <div v-if="this.linkedAccount === 200" class="success">
                <!--desktop-->
                <div class="d-flex flex-column">
                  <div class="d-flex justify-content-center align-items-center mx-auto p-3 icon mb-3">
                    <i class="fa-solid fa-check" aria-hidden="true"></i>
                  </div>
                  <h2 class="font-weight-bold mx-auto px-md-5">{{ $t('connect_app.success.title') }}</h2>
                  <router-link :to="{ name: successRoute }" rel="noopener">
                    <button class="btn btn-primary px-5 py-2 mx-auto mt-3 w-100">
                        {{ $t('connect_app.cta.continue') }}
                    </button>
                  </router-link>
                </div>
              </div>
            </transition>

            <!-- ERROR -->
            <transition name="component-fade" mode="out-in">
              <div v-if="this.accountInUse" class="error">
                <!--desktop-->
                <div class="d-flex flex-column">
                  <div class="alert alert-danger d-flex flex-column flex-md-row align-items-center mx-auto mb-md-5 p-4 mb-4 mt-2">
                    <div class="d-inline-flex justify-content-center align-items-center icon mt-0 mt-md-2 mb-3 mb-md-2 mx-auto mx-md-3">
                      <i class="fa-solid fa-xmark" aria-hidden="true"></i>
                    </div>
                    <p class="ml-md-3 mb-0">{{ $t('connect_app.error.content') }}</p>
                  </div>
                  <div class="mb-3">{{ $t('connect_app.error.copy') }}</div>
                  <div class="d-inline-flex">
                    <button class="btn btn-electroneum px-4 py-2 d-flex justify-content-center w-100 mx-auto" v-if="showEtnSpinner">
                      <b-spinner class="" small label="Large Spinner"></b-spinner>
                    </button>
                    <button v-else class="btn btn-electroneum px-4 py-2 d-flex justify-content-center w-100 mx-auto" v-on:click="loginSubmitEtn">
                      <span class="mr-3">{{ $t('connect_app.cta.sign_in_with') }}</span>
                      <img v-lazy="require('@/assets/Logos/etn-cwp-white.svg')" width="100">
                    </button>
                  </div>
                </div>
              </div>
            </transition>

            <!-- DO THIS LATER -->
            <div v-if="this.linkedAccount === 204" class="w-100 text-align-center mt-4 pt-md-3 pb-5 pb-md-0 d-block explore">
              <router-link :to="{ name: 'home' }" rel="noopener">
                {{ $t('seller_signup_success.do_this_later') }}
              </router-link>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import HeroCurved from '../../components/Global/HeroCurved.vue'
import VueQrcode from 'vue-qrcode'
import ApiService from '../../apiService.js'
import config from '../../config'
import { mapActions } from 'vuex'

export default {
  name: 'ConnectApp',
  components: {
    HeroCurved,
    VueQrcode
  },
  mounted () {
    this.linkedAccountCheck()
    this.qrCodeData()
  },
  data () {
    return {
      pageLoaded: '',
      linkedPoller: 20,
      linkedAccount: '',
      accountInUse: false,
      qrTimer: 600,
      qrLoaded: false,
      qrData: '',
      etnDeeplink: config.etn_deeplink.connecting,
      showEtnSpinner: false,
      successRoute: 'account-type'
    }
  },
  methods: {
    ...mapActions([
      'processJwt',
      'doLoginEtn'
    ]),
    linkedAccountCheck () {
      ApiService.getUserBands().then((dataUser) => {
        this.pageLoaded = true
        if (dataUser.status === 200) {
          localStorage.setItem('accessToken', dataUser.data.data.token)
          this.processJwt().then(() => {
            this.successRoute = this.$store.state.user.band ? 'account-type' : 'myTasks'
            this.linkedAccount = dataUser.status
          })
        } else {
          this.linkedAccount = dataUser.status
        }
      }).catch(() => {
        this.pageLoaded = true
        this.accountInUse = true
      })
    },
    qrCodeData () {
      ApiService.getQrCodeData().then((response) => {
        this.qrData = response.data.data
        this.qrLoaded = true
      })
    },
    loginSubmitEtn () {
      this.showSpinnerEtn = true
      this.doLoginEtn().then(() => {
        this.showSpinnerEtn = false
      })
    }
  },
  watch: {
    qrTimer: {
      handler (value) {
        if (value > 0) {
          setTimeout(() => {
            this.qrTimer--
          }, 1000)
        } else {
          this.qrTimer = 600
          this.qrLoaded = false
          this.qrCodeData()
        }
      },
      immediate: true
    },
    linkedPoller: {
      handler (value) {
        if (value > 0) {
          setTimeout(() => {
            this.linkedPoller--
          }, 1000)
        } else if (this.linkedAccount === 200 || this.accountInUse) {
          this.linkedPoller = 0
        } else {
          this.linkedPoller = 20
          this.linkedAccountCheck()
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>

.component-fade-enter-active, .component-fade-leave-active {
  transition: opacity .3s ease;
}
.component-fade-enter, .component-fade-leave-to {
  opacity: 0;
}

.hero {
  background-image: linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.25) ), url('~@/assets/Connecting/connect-banner-mobile.jpg');
  @include sm-up {
    background-image: url('~@/assets/Connecting/connect-banner-small.jpg');
  }
  @include md-up {
    background-image: url('~@/assets/Connecting/connect-banner-medium.jpg');
  }
  @include lg-up {
    background-image: url('~@/assets/Connecting/connect-banner-large.jpg');
  }
}

h2 {
  width: 100%;
}

.content {
  margin-top: 0;
  .blue {
    color: $brand-sky;
  }
  .btn {
    a {
      color: white;
    }
  }
  .explore {
    a {
      color: $brand-sky;
    }
  }
}

.success {
  .icon {
    font-size: 2.5rem;
    color: white;
    border-radius: 50%;
    background: $green;
  }
}
.error {
  .icon {
    font-size: 2.5rem;
    color: #f8d7da;
    padding: 10px 15px;
    border-radius: 50%;
    background: #721c24;
  }
  .alert {
    width: 100%;
  }
}

.qr-loader {
  width: 200px;
  height: 200px;
  border: 1px solid $grey-border-and-text;
  border-radius: 10px;
}

@include md-up {
  .content {
    border-radius: 18px;
    margin-top: -5rem;
    .btn {
      width: auto !important;
    }
    h2 {
      width: 70%;
    }
  }
  .success {
    h2 {
      width: 70%;
    }
  }
  .error {
    p {
      text-align: left;
    }
    .alert {
      width: 70%;
    }
  }
}

</style>
